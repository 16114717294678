import React, { Component, Fragment } from 'react';
import promiseHandler from '../tools/promiseHandler'
import '../css/referrals.scss';
import pub_sub from '../tools/pub_sub';
import mixpanel from 'mixpanel-browser';
import localization from '../tools/localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

// Safely get and parse userData from localStorage
const userData = (() => {
    const data = window.localStorage.getItem('userData');
    try {
        return data ? JSON.parse(data) : null;
    } catch (e) {
        console.error('Error parsing userData from localStorage:', e);
        return null;
    }
})();
class  ReferralView extends Component {
    constructor(props){
        super(props)

        this.state={
            referredUsers:[],
            pendingReferrals: 0
        }
    }
    render() {
        return (
            
            <div className="referral-view main">
                <div className='cta-container'>
                    <div className='inner-container'>
                        <img className='referral-illustration left' src='https://cc-cdn.pss.co/assets/illustrations/referral_left.svg' alt="referral illustration"></img>
                        <div className="invite-container">
                            <h2><b>{localization['referral.cta_header']}</b></h2>
                            <p>{localization['referral.cta_body']}</p>
                            {userData?
                                <Fragment>
                                    <div className='input-container' id="email_invite_container">
                                        <input type="email" pattern=".+@globex\.com" placeholder="example@email.com" id="email_input_1" ></input>
                                        <input type="email" pattern=".+@globex\.com" placeholder="example@email.com" id="email_input_2" ></input>
                                        <input type="email" pattern=".+@globex\.com" placeholder="example@email.com" id="email_input_3" ></input>
                                    </div>
                                    <button className="cta-button" onClick={()=>{this.sendInvites()}}>{localization['referral.cta_button']}</button>
                                </Fragment>
                                :<div className='join-container'>
                                    <button className='green' onClick={()=>{
                                        mixpanel.track("create_account_selected",{
                                            source: "referral_view",
                                            localization: window.location.pathname
                                        })
                                        window.location.replace("/create-account")
                                    }}>{localization['gen.create_account']}</button>
                                    <h4>{localization['referral.create_account']}</h4>
                                </div>
                            }
                        </div>
                        <img className='referral-illustration right' src='https://cc-cdn.pss.co/assets/illustrations/referral_right.svg' alt="referral illustration"></img>
                    </div>
                </div>
                <div className='info-container'>
                    <h3>{localization.formatString(localization['referral.days_available'],
                        <b style={{color: "#613EEB"}}>{(userData? trialDaysRemaining(userData.trial_period_end): 0)}</b>
                    )}</h3>
                    <div className='rewards-container'>
                        <div className='reward-info'>
                            <img src="https://cc-cdn.pss.co/assets/icons/months_earned.svg" alt="months earned"></img>
                            <span>{localization.formatString(localization['referral.months_earned'], (userData?userData.referrals_redeemed:0))}</span>
                            <h4>{localization['gen.earned']}</h4>
                        </div>
                        <div className='divider'></div>
                         <div className='reward-info'>
                            <img src="https://cc-cdn.pss.co/assets/icons/months_pending.svg" alt="months pending"></img>
                            <span>{localization.formatString(localization['referral.months_pending'], this.state.pendingReferrals)}</span>
                            <h4>{localization['gen.pending']}</h4>
                        </div>
                    </div>
                    <span className='c1'>{localization.formatString(localization['referral.savings'],<b>${(userData?(userData.referral_credits * 5):0)}</b>)}</span>
                </div>
                <div className="referrals-container">
                    <h4><b>{localization['referral.referrals_header']}</b></h4>
                    <table>
                        <tbody>
                            <tr className='table-header-row'>
                                <th>{localization['referral.referrals_email']}</th>
                                <th>{localization['referral.referrals_date']}</th>
                                <th>{localization['referral.referrals.status']}</th>
                            </tr>
                            <tr className='table-header-row mobile'>
                                <th>{localization['referral.recipient']}</th>
                            </tr>
                            {this.state.referredUsers.map((item)=>{
                                    let date = new Date((item.update_date !== null ?item.update_date:item.create_date )*1000)
                                    return(
                                    <tr key={"referral_" + item.referred_email}>
                                        <td>{item.referred_email}</td>
                                        <td>{(date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()}</td>
                                        <td style={{textTransform:'capitalize', color: item.status === "accepted"? "#1FB240": "#222222"}}>{localization["referral."+ item.status]}</td>
                                    </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {this.state.referredUsers.length === 0 &&
                        <div className="empty-table">
                            <h4>{localization['referral.no_referrals']}</h4>
                        </div>
                    }
                </div>
                <div className='how-container'>
                    <h3>{localization['referral.how']}</h3>
                    <div className="how-steps">
                        <div className='step-item'>
                            <img src="https://cc-cdn.pss.co/assets/paper_plane.svg" alt="Invite"></img>
                            <h4><b>{localization['referral.send_header']}</b></h4>
                            <span className='p2'>{localization['referral.send_body']}</span>
                        </div>
                        <div className='step-item'>
                            <img src="https://cc-cdn.pss.co/assets/star_a.svg" alt="Register"></img>
                            <h4><b>{localization['referral.registration_header']}Registration</b></h4>
                            <span className='p2'>{localization['referral.registration_body']}.</span>
                        </div>
                        <div className='step-item'>
                            <img src="https://cc-cdn.pss.co/assets/icons/referral_added.svg" alt="Referral Bonus!"></img>
                            <h4><b>{localization['referral.redeem_title']}</b></h4>
                            <span className='p2'>{localization['referral.redeem_body']}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount(){
        if(userData !== null){   
            let referralsRequest = {
                'method': 'GET',
                'url': '/v1/users/referrals',
            }
            
            promiseHandler.promiseRequest(referralsRequest).then((referralsResponse)=>{
                if(referralsResponse.status === 200){            
                    
                    let pendingReferrals = 0;
                    for (let el in referralsResponse.data.referred_users){
                        if (el.status === 'pending')
                            pendingReferrals +=1;
                    }
                        if(referralsResponse.data.user.referral_credits > 0){
                            alert("You've earned " + referralsResponse.data.user.referral_credits + " in Referrals!")
                            let userUpdateRequest = {
                                'method': 'PUT',
                                'url': '/v1/users',
                                'data': {
                                    'referral_credits': 0
                                }
                            }

                            promiseHandler.promiseRequest(userUpdateRequest)
                        }
                    this.setState(()=>({
                        referredUsers: referralsResponse.data.referred_users,
                        pendingReferrals: pendingReferrals
                    }))
                }
            });
        }
        
    }

    sendInvites(){
       let emailInvites = []
       for(let i = 0; i< document.getElementById('email_invite_container').children.length; i++){
           const element = document.getElementById('email_invite_container').children[i]
           if(element.value !== ""){
            emailInvites.push(element.value)
           }
       }

       if(emailInvites.length === 0){
           alert('Add emails')
       }
       else{
            let inviteRequest = {
                'method': 'POST',
                'url': '/v1/users/invite',
                'data': {
                    invites: emailInvites
                }
            }

            promiseHandler.promiseRequest(inviteRequest).then((inviteResponse)=>{
                if(inviteResponse.status === 200){
                    inviteResponse.data.errors.forEach(error => {
                        if(error.error_type === "user_exists" || error.error_type === "duplicate_email"){
                            pub_sub.publish.new_toast_message({
                                type: 'error',
                                header: localization['toast.gen_error_header'],
                                message: localization.formatString(localization['toast.referral_error_exists'], error.email),
                                pathname:'/referral'
                            })
                        }
                        else if(error.error_type === "pending_invite_exists"){
                            pub_sub.publish.new_toast_message({
                                type: 'error',
                                header: localization['toast.gen_error_header'],
                                message: localization.formatString(localization['toast.referral_error_pending'], error.email),
                                pathname:'/referral'
                            })
                        }
                        else{
                            pub_sub.publish.new_toast_message({
                                type: 'error',
                                header: localization['toast.gen_error_header'],
                                message: localization['toast.try_again'],
                                pathname:'/referral'
                            })
                        }
                        
                        
                    });
                    
                    inviteResponse.data.invites.forEach(invite =>{
                        if(!process.env.REACT_APP_TEST_ENVIRONMENT){
                            mixpanel.track("user_invited",{
                                "invite": invite.email
                            })
                        }
                        pub_sub.publish.new_toast_message({
                            type: 'success',
                            header: localization['toast.referral_header'],
                            message: localization.formatString(localization['toast.referral_body'],invite.email),
                            pathname:'/referral'
                        })
                    })
                }
            });
       }
    }
}
function trialDaysRemaining(trialPeriodEnd){
    let dateDifference = (trialPeriodEnd * 1000) - new Date().getTime();
    const daysRemaining = Math.ceil(dateDifference / (1000 * 3600 * 24))
    return (daysRemaining >= 0? daysRemaining:0);
}
export default ReferralView;